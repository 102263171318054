import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import type { BackendTypes } from '@tf/api';
import { Input, Select } from '@tf/ui';

import { useConfigContext } from '../../../hooks';
import { transformData } from '../../../utils';
import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

interface Props extends DefaultInputProps {
	enumName?: string;
	isSearchable?: boolean;
	options?: BackendTypes.SelectOption[];
	currency?: string;
}

export default function SelectInput({
	name,
	label,
	description,
	enumName,
	placeholder,
	isReadOnly,
	isSearchable,
	isRequired,
	className,
	tooltip,
	currency,
	...props
}: Props) {
	const form = useFormContext();
	const getEnumOptions = useConfigContext((s) => s.getEnumOptions);
	const options = enumName ? getEnumOptions(enumName) : props.options || [];

	useEffect(() => {
		const value = form.getValues(name);
		form.setValue(name, value || undefined);
	}, []);

	return (
		<Controller
			control={form.control}
			name={name}
			render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
				<Input.Wrapper
					id={name}
					className={className}
					label={`${label} ${currency ? '(' + currency + ')' : ''}`}
					required={isRequired}
				>
					<Select
						id={name}
						ref={ref}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						error={Boolean(error)}
						placeholder={isReadOnly ? 'Not specified' : placeholder || 'Select option...'}
						description={description}
						data={transformData(options)}
						readOnly={isReadOnly}
						data-readonly={isReadOnly}
						rightSection={
							tooltip || error ? (
								<TooltipIcon tooltip={tooltip} error={error} />
							) : isReadOnly ? (
								<div />
							) : undefined
						}
						searchable={isSearchable || options.length > 5}
					/>
				</Input.Wrapper>
			)}
		/>
	);
}
