import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import type { BackendTypes } from '@tf/api';
import { Button, createStyles, DateInput, Flex, Icon, Input, Modal, Select } from '@tf/ui';
import { D, fmt } from '@tf/utils';

import { useConfigContext } from '../../../../../../hooks';
import { transformData } from '../../../../../../utils';

import type { DocumentCheckPayload } from './DocumentsCheckComponent';
import { getSegmentsData } from './utils';

const useStyles = createStyles(({ colors }) => ({
	checkButton: {
		fontWeight: 500,
		fontSize: 13,
		border: `1px solid transparent`,
		backgroundColor: colors.gray[1],
		'&:disabled': {
			color: colors.gray[5],
			backgroundColor: colors.gray[1],
		},
	},
	select: {
		margin: '.75rem .75rem .25rem .75rem',
	},
	footer: {
		marginTop: '1rem',
		padding: '.75rem .75rem .75rem',
		border: `1px solid ${colors.light[2]}`,
	},
}));

interface Props {
	onCheckDocument: () => void;
	checkedDocument: BackendTypes.DocumentCheckJobInfo;
	files: BackendTypes.FieldFile[];
	documentCheckPayload: DocumentCheckPayload;
	onDocumentCheckPayloadChange: (key: keyof DocumentCheckPayload | null, value: string) => void;
}

export const DocumentCheckButton: React.FC<Props> = ({
	onCheckDocument,
	checkedDocument,
	files,
	documentCheckPayload,
	onDocumentCheckPayloadChange,
}) => {
	const { classes } = useStyles();
	const formContext = useFormContext();

	const formData = formContext.getValues();

	const fieldsFromSegment = Object.entries(getSegmentsData(formData)).map(([key, value]) => ({
		key,
		value,
	}));

	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => setIsModalOpen(!isModalOpen);
	const handleCloseModal = () => {
		onDocumentCheckPayloadChange(null, '');
		setIsModalOpen(false);
	};

	useEffect(() => {
		fieldsFromSegment.forEach(({ key, value }) => {
			if (value) {
				onDocumentCheckPayloadChange(key as keyof DocumentCheckPayload, value);
			}
		});
	}, [isModalOpen]);

	const getEnumOptions = useConfigContext((s) => s.getEnumOptions);
	const countries = getEnumOptions('common__enum__country') || [];
	const documentsKinds = getEnumOptions('DocumentCheckKind') || [];

	const selectFilesOptions = files.map((file) => ({
		label: file.filename,
		value: file.storageKey,
	}));

	const commonConditions =
		!files.length ||
		!documentCheckPayload.frontSideDocument ||
		!documentCheckPayload.documentType ||
		!documentCheckPayload.residencyCountry ||
		!documentCheckPayload.dateOfBirth ||
		!documentCheckPayload.firstName ||
		!documentCheckPayload.lastName;

	const idCardSpecificCondition =
		documentCheckPayload.documentType === 'ID_CARD' && !documentCheckPayload.backSideDocument;

	const isSubmitButtonDisabled = commonConditions || idCardSpecificCondition;

	return (
		<>
			<Button
				size="sm"
				variant="light"
				className={classes.checkButton}
				leftSection={<Icon.IconCheck size={16} stroke={1.8} />}
				onClick={toggleModal}
				disabled={!files?.length}
			>
				{checkedDocument?.payload ? 'Check new document' : 'Check the document'}
			</Button>
			<Modal centered opened={isModalOpen} onClose={handleCloseModal} title="Choose the document">
				<Input.Wrapper required className={classes.select} label="First name">
					<Input
						value={documentCheckPayload.firstName}
						onChange={({ target }) => onDocumentCheckPayloadChange('firstName', target.value)}
					/>
				</Input.Wrapper>
				<Input.Wrapper required className={classes.select} label="Last name">
					<Input
						value={documentCheckPayload.lastName}
						onChange={({ target }) => onDocumentCheckPayloadChange('lastName', target.value)}
					/>
				</Input.Wrapper>
				<DateInput
					required
					placeholder="Select date..."
					className={classes.select}
					label="Date of birth"
					value={
						documentCheckPayload.dateOfBirth
							? D.jsDateFromString(documentCheckPayload.dateOfBirth)
							: null
					}
					onChange={(value) => {
						if (value) {
							onDocumentCheckPayloadChange('dateOfBirth', fmt.toDate(value, { preset: 'date_struct' }));
						}
					}}
				/>
				<Select
					required
					className={classes.select}
					label="Document type"
					value={documentCheckPayload.documentType}
					onChange={(value) => {
						if (value) {
							onDocumentCheckPayloadChange('documentType', value);
						}
					}}
					data={documentsKinds}
				/>
				<Select
					required
					className={classes.select}
					value={documentCheckPayload.residencyCountry}
					onChange={(value) => {
						if (value) {
							onDocumentCheckPayloadChange('residencyCountry', value);
						}
					}}
					searchable
					label="Country of issue"
					data={transformData(countries)}
				/>
				<Select
					required
					className={classes.select}
					value={documentCheckPayload.frontSideDocument}
					onChange={(value) => {
						if (value) {
							onDocumentCheckPayloadChange('frontSideDocument', value);
						}
					}}
					searchable
					label="Document front side"
					data={selectFilesOptions.filter(
						(file) => file.value !== documentCheckPayload.backSideDocument
					)}
				/>
				<Select
					required={documentCheckPayload.documentType === 'ID_CARD'}
					className={classes.select}
					value={documentCheckPayload.backSideDocument}
					onChange={(value) => {
						if (value) {
							onDocumentCheckPayloadChange('backSideDocument', value);
						}
					}}
					searchable
					label="Document back side"
					data={selectFilesOptions.filter(
						(file) => file.value !== documentCheckPayload.frontSideDocument
					)}
				/>
				<Flex className={classes.footer}>
					<Button mr="0.75rem" fullWidth variant="light" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button
						fullWidth
						disabled={isSubmitButtonDisabled}
						onClick={() => {
							onCheckDocument();
							handleCloseModal();
						}}
					>
						Check
					</Button>
				</Flex>
			</Modal>
		</>
	);
};
