import React from 'react';

import type { BoxProps } from '@mantine/core';
import { Box, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import type { ReactNode } from 'react';

interface Props extends React.PropsWithChildren, BoxProps {
	title?: string | ReactNode;
	innerPadding?: string;
}

const useStyles = createStyles(({ colors, fontSizes }) => ({
	title: {
		color: colors.gray[4],
		fontWeight: 600,
		fontSize: fontSizes.xs,
		letterSpacing: '.5px',
		marginBottom: '.5rem',
		cursor: 'default',
		userSelect: 'none',
	},
	tooltip: {
		color: colors.gray[4],
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	card: {
		borderStyle: 'solid',
		borderWidth: 0.5,
		borderColor: colors.gray[2],
		backgroundColor: '#fff',
		boxShadow: '0 4px 20px -4px rgb(0 0 0 / 10%)',
		borderRadius: 8,
	},
}));

const Card: React.FC<Props> = ({ children, title, innerPadding, ...boxProps }) => {
	const { classes } = useStyles();

	return (
		<Box {...boxProps}>
			{typeof title === 'string' ? (
				<Box className={classes.header}>
					<Text className={classes.title}>{title?.toUpperCase()}</Text>
				</Box>
			) : (
				title
			)}
			<Box className={classes.card} p={innerPadding}>
				{children}
			</Box>
		</Box>
	);
};

export default Card;
