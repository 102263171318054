import React from 'react';

import type {
	ComboboxItem,
	ComboboxLikeRenderOptionInput,
	CSSVariablesResolver,
	DefaultMantineColor,
} from '@mantine/core';
import { Box, MantineProvider } from '@mantine/core';
import type { EmotionStyles } from '@mantine/emotion';
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';

export interface ThemeProviderProps {
	children: React.ReactNode;
}

type Colors = Partial<
	Record<
		DefaultMantineColor,
		[string, string, string, string, string, string, string, string, string, string]
	>
>;

const fontFamily = 'Inter, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif';

const colors: Colors = {
	gray: [
		'#F9FAFB',
		'#F2F4F7',
		'#EAECF0',
		'#D0D5DD',
		'#98A2B3',
		'#667085',
		'#475467',
		'#344054',
		'#1D2939',
		'#101828',
	],
	brand: [
		'#EFF4FF',
		'#D1E0FF',
		'#879FE1',
		'#84ADFF',
		'#528BFF',
		'#2970FF',
		'#155EEF',
		'#004EEB',
		'#1849A9',
		'#194185',
	],
	light: [
		'#FCFDFF',
		'#F2F4F7',
		'#EAECF0',
		'#D0D5DD',
		'#98A2B3',
		'#667085',
		'#475467',
		'#344054',
		'#1D2939',
		'#101828',
	],
};

interface ThemeComponent {
	classNames?: any;
	styles?: EmotionStyles;
	vars?: any;
	defaultProps?: any;
}

export type ThemeComponents = Record<string, ThemeComponent>;

const themeComponents: Record<string, ThemeComponent> = {
	Alert: {
		styles(theme, params) {
			return {
				root: {
					padding: '.5rem 1rem',
					borderWidth: 1,
					borderStyle: 'solid',
					...(params.color && {
						borderColor: theme.colors[params.color][3],
					}),
				},
				message: { fontSize: theme.fontSizes.sm },
				icon: { marginRight: 6 },
			};
		},
	},
	AppShell: {
		defaultProps: {
			padding: 0,
			// fixed: true,
		},
		styles: ({ colors }) => ({
			root: {
				backgroundColor: colors.light[0],
			},
		}),
	},
	Breadcrumbs: {
		defaultProps: {
			separator: '/',
		},
		styles: {
			breadcrumb: { fontSize: 12 },
			separator: { margin: '0 .375rem' },
		},
	},
	Button: {
		defaultProps: {
			size: 'md',
			variant: 'gradient',
			gradient: { from: 'brand', to: 'blue' },
			loaderProps: { size: 14 },
		},
		styles: (theme, params) => {
			return {
				root: {
					transition: 'all .15s ease-in-out',
					height: '2.5rem',

					...(params.size === 'md' && {
						height: '2.25rem',
						fontSize: '.85rem',
					}),
					...(params.size === 'sm' && {
						height: '2rem',
						fontSize: '.8rem',
					}),
					...(params.size === 'xs' && {
						height: '1.8rem',
						fontSize: '.8rem',
					}),
				},
				leftIcon: {
					...(params.size === 'md' && {
						marginLeft: '.375rem',
						marginRight: '.5rem',
					}),
					...(params.size === 'sm' && {
						marginLeft: '.225rem',
						marginRight: '.5rem',
					}),
					...(params.size === 'xs' && {
						marginLeft: '.375rem',
						marginRight: '.5rem',
					}),
				},
			};
		},
	},
	Checkbox: {
		styles: {
			label: {
				paddingLeft: '0.5rem',
			},
		},
	},
	Modal: {
		defaultProps: {
			radius: 'sm',
		},
		styles: ({ colors }) => ({
			header: {
				display: 'flex',
				padding: '.75rem 1rem',
				margin: 0,
				borderWidth: '0 0 1px',
				borderStyle: 'solid',
				borderColor: colors.light[2],
			},
			title: {
				fontSize: '1.2rem',
				fontWeight: 500,
			},
			body: { padding: 0 },
		}),
	},
	InputWrapper: {
		styles: {
			label: {
				fontSize: 14,
				marginBottom: '.25rem',
				cursor: 'pointer',
			},
		},
	},
	Loader: { defaultProps: { color: 'brand', type: 'bars' } },
	Input: {
		defaultProps: {
			variant: 'filled',
			autoComplete: 'off',
		},
		styles: ({ colors }) => ({
			input: {
				transition: 'border-color .15s ease',
				borderWidth: 1,
				borderStyle: 'solid',
				borderRadius: 3,
				borderColor: colors.gray[3],

				'&:hover': {
					borderColor: colors.brand[3],
				},
				'&:focus': {
					outlineStyle: 'solid',
					outlineWidth: 0.75,
					outlineColor: colors.brand[2],
				},
				'&[data-error="true"]': {
					borderColor: colors.red[3],
					'&:focus': {
						outlineStyle: 'solid',
						outlineWidth: 0.75,
						outlineColor: `${colors.red[6]} !important`,
						borderColor: colors.red[3],
					},
				},
				'&[data-readonly="true"]': {
					backgroundColor: '#fbfbfc',
					borderWidth: '0 0 1px',
					borderRadius: 0,

					'&:hover': {
						borderColor: colors.light[2],
					},
					'&:focus': {
						borderColor: colors.light[2],
						outlineColor: 'transparent',
					},
				},
			},
			invalid: {
				color: 'inherit',
				'::placeholder': {
					color: '#5d5f67',
				},
			},
		}),
	},
	MultiSelect: {
		defaultProps: {
			withCheckIcon: false,
			allowDeselect: false,
			styles: {
				option: {
					padding: '9px 12px',
				},
			},
		},
	},
	Select: {
		defaultProps: {
			maxDropdownHeight: 280,
			allowDeselect: false,
			withCheckIcon: false,
			styles: {
				option: {
					padding: 0,
				},
			},
			renderOption: (item: ComboboxLikeRenderOptionInput<ComboboxItem>) => (
				<Box
					p="8px 11px"
					w="100%"
					sx={({ colors }) => {
						if (item.checked) {
							return { backgroundColor: colors.brand[6], borderRadius: '4px', color: colors.light[0] };
						}
						return {};
					}}
				>
					{item.option.label}
				</Box>
			),
			comboboxProps: {
				withinPortal: true,
			},
			placeholder: 'Select option...',
			'data-readonly': false,
		},
		styles: ({ colors }) => ({
			input: {
				'&:focus': {
					outlineStyle: 'solid',
					outlineWidth: 0.75,
					outlineColor: colors.brand[2],
				},

				'&[data-readonly="false"]': {
					backgroundColor: colors.light[1],
					borderRadius: 3,
					borderWidth: 1,
					borderColor: colors.gray[3],
					'&:hover': {
						borderColor: colors.brand[3],
					},
					'&:focus': {
						borderColor: colors.brand[5],
						outlineStyle: 'solid',
						outlineWidth: 0.75,
						outlineColor: colors.brand[2],
					},
				},

				'&[data-error="true"]': {
					borderColor: `${colors.red[3]} !important`,
					backgroundColor: colors.light[1],
					color: '#000',
					'&::placeholder': {
						color: '#000',
					},
					'&:focus': {
						outlineStyle: 'solid',
						outlineWidth: 0.75,
						outlineColor: `${colors.red[6]} !important`,
						borderColor: colors.red[3],
					},
				},
			},
			item: {
				fontSize: '.8rem',
				padding: '0.5rem 1rem',
			},
			dropdown: {
				zIndex: 'auto',
			},
		}),
	},

	Tabs: {
		styles: {
			root: { backgroundColor: 'transparent' },
			tab: { transition: 'border-color .15s ease-in-out' },
			labelActive: { color: `${colors.brand?.[6]} !important` },
		},
	},

	DateInput: {
		styles: ({ colors }) => ({
			input: {
				'&[data-read-only="true"]': {
					userSelect: 'all',
					backgroundColor: '#fbfbfc',
					borderWidth: '0 0 1px',
					borderRadius: 0,
					'::placeholder': {
						color: 'black',
					},

					'&:hover': {
						borderColor: colors.light[2],
					},
					'&:focus': {
						borderColor: colors.light[2],
						outlineColor: 'transparent',
					},
				},
			},
		}),
	},

	Menu: {
		defaultProps: {
			shadow: 'sm',
			withArrow: true,
		},
	},

	Tooltip: {
		defaultProps: {
			withArrow: true,
			size: 'xs',
			openDelay: 500,
		},
		styles: {
			tooltip: {
				fontSize: 12,
			},
		},
	},
	Badge: {
		styles: ({ colors }) => ({
			root: {
				backgroundColor: colors.brand[0],
				color: colors.brand[6],
				fontWeight: 600,
			},
		}),
	},
};

const resolver: CSSVariablesResolver = ({ colors }) => ({
	variables: {
		'--mantine-color-brand-light': colors.brand[0],
	},
	light: { '--mantine-color-brand-light': colors.brand[0] },
	dark: { '--mantine-color-brand-light': colors.brand[0] },
});

const ThemeProvider = ({ children }: ThemeProviderProps) => {
	return (
		<MantineProvider
			stylesTransform={emotionTransform}
			cssVariablesResolver={resolver}
			theme={{
				cursorType: 'pointer',
				focusRing: 'auto',
				respectReducedMotion: true,

				colors,
				primaryColor: 'brand',
				primaryShade: { light: 6, dark: 7 },

				fontFamily,
				fontFamilyMonospace: 'Monaco, Courier, monospace',

				fontSizes: {
					xs: '.75rem',
					sm: '.825rem',
					md: '.9rem',
					lg: '1.2rem',
					xl: '2rem',
				},
				spacing: {
					xs: '.75rem',
					sm: '.85rem',
					md: '1rem',
					lg: '2rem',
					xl: '3rem',
				},
				headings: {
					fontFamily,
					fontWeight: '500',
					sizes: {
						h1: {
							fontSize: '2rem',
							fontWeight: '600',
							lineHeight: '1.25',
						},
						h2: {
							fontSize: '1.625rem',
							fontWeight: '500',
							lineHeight: '1.25',
						},
						h3: {
							fontSize: '1.375rem',
							fontWeight: '500',
							lineHeight: '1.25',
						},
					},
				},
				defaultRadius: 4,

				other: {
					floatingedges: {
						flexDirection: 'column',
						display: 'flex',
						flexGrow: 1,
						height: '100%',
					},
					'.floatingedges .react-flow__handle': {
						opacity: 0,
					},
				},
				components: themeComponents,
			}}
		>
			<MantineEmotionProvider>{children}</MantineEmotionProvider>
		</MantineProvider>
	);
};

export default ThemeProvider;
