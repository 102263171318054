import React from 'react';

import type { PopoverProps } from '@mantine/core';
import {
	ActionIcon,
	Box,
	Button,
	Indicator,
	Input,
	Popover,
	Select,
	Stack,
	Tooltip,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconFilter } from '@tabler/icons-react';
import type { MRT_ColumnFiltersState } from 'mantine-react-table';

import type { FilterItem } from './types';

interface Props {
	availableFilters: FilterItem[];
	activeFilters: MRT_ColumnFiltersState;
	setFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>;
	popoverPosition?: PopoverProps['position'];
}

export const FilterButton: React.FC<Props> = ({
	availableFilters,
	activeFilters,
	popoverPosition,
	setFilters,
}) => {
	const [isFiltersPopoverOpen, setFiltersPopoverOpen] = React.useState(false);
	const hasActiveFilters = activeFilters.length > 0;

	const applyHandler = () => {
		setFiltersPopoverOpen(false);
	};

	const clearHandler = () => {
		setFilters([]);
		setFiltersPopoverOpen(false);
	};

	if (availableFilters.length === 0) {
		return null;
	}

	return (
		<Popover
			width={400}
			opened={isFiltersPopoverOpen}
			onClose={() => setFiltersPopoverOpen(false)}
			transitionProps={{ exitDuration: 0 }}
			trapFocus
			position={popoverPosition || 'bottom-end'}
			withArrow
			withinPortal
			shadow="md"
		>
			<Popover.Target>
				<Tooltip label="Set filters" position="left" withArrow>
					<Indicator
						disabled={!hasActiveFilters}
						color="red"
						size={20}
						processing
						label={activeFilters.length}
					>
						<ActionIcon
							color="brand"
							variant="light"
							size="lg"
							ml={9}
							h={36}
							w={36}
							onClick={() => setFiltersPopoverOpen(true)}
						>
							<IconFilter size={18} stroke={1.8} />
						</ActionIcon>
					</Indicator>
				</Tooltip>
			</Popover.Target>

			<Popover.Dropdown
				sx={(theme) => ({
					background: theme.white,
				})}
			>
				<Stack gap=".5rem">
					{availableFilters.map((filter) => {
						if (filter.type === 'select' && Array.isArray(filter.value)) {
							const value = (activeFilters.find((f) => f.id === filter.name)?.value as string) || null;
							return (
								<Select
									comboboxProps={{ withinPortal: false }}
									key={filter.name}
									label={filter.label}
									value={value}
									data={filter.value}
									onChange={(value) => {
										setFilters([
											...(activeFilters.filter((item) => item.id !== filter.name) || []),
											{
												id: filter.name,
												value,
											},
										]);
									}}
								/>
							);
						}

						if (filter.type === 'checkbox' && typeof filter.value === 'number') {
							const value = (activeFilters.find((f) => f.id === filter.name)?.value as number) || null;
							return (
								<Box key={filter.name}>
									<Input.Wrapper label={filter.label}>
										<Button.Group>
											<Button
												variant={value ? 'filled' : 'outline'}
												size="xs"
												onClick={() => {
													setFilters([
														...(activeFilters?.filter((item) => item.id !== filter.name) || []),
														{ id: filter.name, value: filter?.value },
													]);
												}}
											>
												Yes
											</Button>
											<Button
												variant={!value ? 'filled' : 'outline'}
												size="xs"
												onClick={() => {
													setFilters((prev) => prev.filter((item) => item.id !== filter.name) || []);
												}}
											>
												No
											</Button>
										</Button.Group>
									</Input.Wrapper>
								</Box>
							);
						}

						if (filter.type === 'date' && Array.isArray(filter.value)) {
							const value =
								(activeFilters.find((f) => f.id === filter.name)?.value as [Date | null, Date | null]) ||
								undefined;
							return (
								<Box key={filter.name}>
									<DatePickerInput
										type="range"
										label="Dates"
										placeholder="Pick dates range"
										onChange={(value) => {
											setFilters([
												...(activeFilters.filter((item) => item.id !== filter.name) || []),
												{
													id: filter.name,
													value,
												},
											]);
										}}
										value={value}
										mx="auto"
										maw={400}
									/>
								</Box>
							);
						}
					})}
				</Stack>

				<Box sx={{ display: 'flex', marginTop: '.75rem' }}>
					<Button fullWidth variant="light" size="sm" mr=".75rem" onClick={clearHandler}>
						Clear all
					</Button>
					<Button fullWidth size="sm" loaderProps={{ size: 14 }} onClick={applyHandler}>
						Apply Filters
						{activeFilters.length > 0 && ` (${activeFilters.length})`}
					</Button>
				</Box>
			</Popover.Dropdown>
		</Popover>
	);
};
