import React, { useState } from 'react';

import { ActionIcon, Anchor, Box, createStyles, Icon, TFText, Tooltip } from '@tf/ui';
import { fmt } from '@tf/utils';

import type { SanctionListItem } from '../types';

import { ItemDetails } from './ItemDetails';

const useStyles = createStyles(({ colors }) => ({
	root: {
		padding: '0 1rem',
	},
	border: {
		borderColor: colors.light[2],
	},
	row: {
		height: '2.5rem',
		display: 'flex',
		alignItems: 'center',
	},
	chevron: {
		color: colors.brand[6],
		marginLeft: '1rem',
		padding: '.25rem',
		borderRadius: '50%',
	},
}));

interface Props {
	item: SanctionListItem;
}

export const Item: React.FC<Props> = ({ item }) => {
	const { classes } = useStyles();
	const [isOpen, setIsOpen] = useState(false);

	if (!item.name) {
		return null;
	}

	const hasUrl = !!item.url;
	const hasListingDates = item.listing_ended_utc || item.listing_started_utc;
	const fontWeight = isOpen ? 600 : 400;

	return (
		<Box className={classes.root}>
			<Box className={classes.row}>
				{hasUrl ? (
					<Anchor
						sx={{
							display: 'flex',
							alignItems: 'center',
							flex: 1,
							height: '2.5rem',
							fontWeight,
						}}
						href={item.url}
						target="_blank"
						rel="noreferrer"
					>
						<TFText lineClamp={1} size="sm" mr=".25rem" fw={fontWeight}>
							{item.name}
						</TFText>
						<Icon.IconExternalLink size={14} />
					</Anchor>
				) : (
					<TFText sx={{ flex: 1 }} lineClamp={1} size="sm">
						{item.name}
					</TFText>
				)}

				{hasListingDates && (
					<TFText size={'sm'}>
						Listed:{' '}
						{item.listing_started_utc
							? fmt.toDate(item.listing_started_utc, { preset: 'full_date' })
							: 'N/A'}
						{' – '}
						{item.listing_ended_utc
							? fmt.toDate(item.listing_ended_utc, { preset: 'full_date' })
							: 'Present'}
					</TFText>
				)}

				<Tooltip label={!isOpen ? 'Show details' : 'Hide details'} position="left" withinPortal>
					<ActionIcon onClick={() => setIsOpen(!isOpen)} variant="light" className={classes.chevron}>
						{isOpen ? <Icon.IconChevronUp size={24} /> : <Icon.IconChevronDown size={24} />}
					</ActionIcon>
				</Tooltip>
			</Box>

			{isOpen && <ItemDetails items={item.list || []} />}
		</Box>
	);
};
