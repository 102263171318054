import React from 'react';

import { Flex, Group, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { IconX } from '@tabler/icons-react';
import type { MRT_ColumnFiltersState } from 'mantine-react-table';

import type { FilterItem } from './types';

const useStyles = createStyles(({ colors, fontSizes }) => ({
	root: {
		margin: '0 .75rem .75rem',
	},
	removeIcon: {
		cursor: 'pointer',
		marginLeft: '5px',
		color: colors.brand[7],
	},

	badge: {
		height: '1.5rem',
		color: colors.brand[7],
		backgroundColor: colors.brand[0],
		borderRadius: '.75rem',
		padding: '0 .75rem',
		fontSize: fontSizes.sm,
		lineHeight: 1,
		userSelect: 'none',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

interface Props {
	availableFilters: FilterItem[];
	activeFilters: MRT_ColumnFiltersState;
	setFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>;
}

export const ActiveFilters: React.FC<Props> = ({ activeFilters, availableFilters, setFilters }) => {
	const { classes } = useStyles();

	if (activeFilters.length === 0) {
		return null;
	}

	const removeFilter = (id: string) => {
		setFilters((prev) => prev.filter((filter) => filter.id !== id));
	};

	return (
		<Group className={classes.root} gap=".75rem">
			{activeFilters.map((filter) => {
				const filterData = availableFilters.find((item) => item.name === filter.id);
				if (!filterData) {
					return null;
				}
				let value = '';
				if (Array.isArray(filterData.value)) {
					value = filterData.value.find((f) => f.value === filter.value)?.label || 'N/A';
				}
				if (typeof filterData.value === 'number') {
					value = filter.value ? 'Yes' : 'No';
				}

				return (
					<Flex key={filter.id} className={classes.badge}>
						<Text fw={500}>{filterData.label}</Text>
						{': '}
						{value}
						<IconX size={15} className={classes.removeIcon} onClick={() => removeFilter(filter.id)} />
					</Flex>
				);
			})}
		</Group>
	);
};
