import React, { useEffect, useState } from 'react';

import { Box, createStyles, Group, lighten, Loader, Stack, TFText } from '@tf/ui';

import { JobStatus } from '../../../../types';

import { DocumentsCheckGenericResult } from './DocumentCheckGenericResult';
import type { DocumentCheckJob } from './types';

const useStyles = createStyles(({ colors, radius }) => ({
	infoBlockWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	infoBlockContent: {
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		cursor: 'default',
		color: colors.brand[6],
	},
	errorBlockWrapper: {
		backgroundColor: lighten(colors.red[1], 0.6),
	},
	fileName: {
		maxWidth: '600px',
	},
	status: {
		fontWeight: 'bold',
		maxWidth: '600px',
	},
	clearSubStatusColor: {
		color: colors.green[8],
	},
	cautionSubStatusColor: {
		color: colors.yellow[7],
	},
	suspectedSubStatusColor: {
		color: colors.orange[7],
	},
	rejectedSubStatusColor: {
		color: colors.red[6],
	},
}));

type JobError = {
	documentation?: string;
	identifier?: string;
	message?: string;
	severity?: string;
};

interface Props {
	job: DocumentCheckJob | null;
	isReadOnly: boolean;
}

export const DocumentsCheckStatus: React.FC<Props> = ({ job, isReadOnly }) => {
	const { classes } = useStyles();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		if (!job) return;
		if (
			job.result &&
			'details' in job.result &&
			typeof job.result.details === 'object' &&
			job.result.details !== null &&
			'error' in job.result.details
		) {
			try {
				const error: JobError = JSON.parse(job.result.details.error as string);
				if (error?.message) {
					setErrorMessage(error.message);
				}
			} catch (e) {
				console.error('Error parsing job result details:', e);
			}
		}
	}, [job]);

	if (!job?.status) {
		return null;
	}

	return (
		<Group
			gap="sm"
			sx={({ radius, colors }) => ({
				display: 'flex',
				alignItems: 'center',
				borderRadius: radius.sm,
				backgroundColor: colors.light[0],
			})}
		>
			{job.status === JobStatus.IN_PROGRESS || job.status === JobStatus.PENDING ? (
				<Box className={classes.infoBlockWrapper}>
					<Group>
						<Loader size={18} />
						<TFText size="sm" lineClamp={2} className={classes.infoBlockContent}>
							The document is being checked. This operation can take up to 20 minutes depending on the
							document quality.
						</TFText>
					</Group>
				</Box>
			) : null}

			{job.status === JobStatus.FAILED ? (
				<Box className={`${classes.infoBlockWrapper} ${!isReadOnly && classes.errorBlockWrapper}`}>
					<Stack gap="xs">
						<TFText size="sm" lineClamp={1}>
							Could not check the document.
						</TFText>

						{!isReadOnly ? (
							<TFText size="sm" lineClamp={3}>
								{errorMessage ? errorMessage : 'Please try again later or contact support.'}
							</TFText>
						) : null}
					</Stack>
				</Box>
			) : null}

			{job.status === JobStatus.SUCCESS && <DocumentsCheckGenericResult job={job} />}
		</Group>
	);
};
