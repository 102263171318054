import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { Alert, Box, createStyles, Icon, Input, Stack, TFText, UnstyledButton } from '@tf/ui';
import type { FieldDefinition } from '@tf/utils';
import { RuleType } from '@tf/utils';

import { useConfigContext, useFormStore, useSegmentContext } from '../../../../hooks';
import { StructField } from '../../../StructField';
import type { FieldProps } from '../../types';

import ArrayFieldItem from './ArrayFieldItem';

const useStyles = createStyles(({ colors, radius }) => ({
	addButton: {
		display: 'flex',
		alignItems: 'center',
		padding: '.5rem .75rem',
		margin: '.25rem 0',
		backgroundColor: colors.gray[1],
		fontSize: 13,
		fontWeight: 500,
		borderRadius: radius.sm,
		color: colors.gray[7],
		transition: 'all 150ms ease',
		border: `1px solid transparent`,
		'&:hover': {
			backgroundColor: colors.brand[0],
			color: colors.brand[7],
		},
	},
	requiredButton: {
		border: `1px solid ${colors.red[3]}`,
		backgroundColor: colors.red[1],
		'&:hover': {
			backgroundColor: colors.red[2],
			color: colors.dark[5],
		},
	},
	requiredMark: {
		color: colors.red[6],
		marginLeft: '2px',
	},
	empty: {
		paddingLeft: '.75rem',
		borderWidth: '0 0 1px',
		borderRadius: 0,
		backgroundColor: '#fbfbfc',
		color: '#667085',
		borderColor: colors.light[2],
	},
}));

const ArrayField: React.FC<FieldProps> = ({ def }) => {
	const form = useFormStore((s) => s);
	const { control, getValues } = useFormContext();
	const { classes } = useStyles();

	// * Consume segment context
	const { identity } = useSegmentContext();

	// * Array Handlers
	const {
		append,
		remove,
		fields: items,
	} = useFieldArray({
		control,
		name: `${identity.segmentKind}.${def.name}`,
	});
	const appendItem = () => append({}, { shouldFocus: false });
	const removeItem = (idx: number) => remove(idx);

	// * Handler for getting nested struct definition
	const { getStructDefinition } = useConfigContext((s) => s);
	const structDef = getStructDefinition(def.ref as string);
	const refs = Object.values(structDef.fields).map((field) => field.ref);
	const isRequired = form
		.getFieldValidationRules(identity.segmentKind, def.name)
		.some((rule) => rule.rule === 'required' && rule.type === RuleType.SEGMENT);

	const isEmptyArray = !getValues(`${identity.segmentKind}.${def.name}`)?.length;
	const isErrors = form.getFieldErrors(identity.segmentKind, def.name).length;
	// * Get access mode
	const isReadOnly = useFormStore((s) => s.getAccessMode(identity.segmentKind) === 'VIEW');

	return (
		<>
			{isReadOnly && items.length === 0 && (
				<>
					<Input.Label required={isRequired}>{def.visual.label ?? def.name}</Input.Label>
					<Alert className={classes.empty}>
						<TFText c="dimmed">No records specified</TFText>
					</Alert>
				</>
			)}

			<Stack gap={12}>
				{items.map((item, idx) => {
					const fields = Object.values(structDef.fields);
					const structFields: FieldDefinition[] = fields
						.filter((f) => !form.hiddenFields.includes(f.name))
						.map((f) => ({
							...f,
							name: `${def.name}.${idx}.${f.name}` as const,
						}));

					const hasFile = fields.some((field) => field.name === 'files' && field.isMultiple);
					const isRequiredFile = form
						.getFieldValidationRules(identity.segmentKind, def.name)
						.some((rule) => rule.rule === 'required' && rule.type === RuleType.DOCUMENT);

					return (
						<ArrayFieldItem
							index={idx}
							key={item.id}
							title={def.visual.label ?? def.name}
							onDelete={() => removeItem(idx)}
							isReadOnly={isReadOnly}
							tooltip={def.visual.tooltip}
						>
							<StructField
								structFields={structFields}
								def={def}
								idx={idx}
								visualRules={form.getFieldVisualRules(identity.segmentKind, `${def.name}.${idx}`)}
								hasFile={hasFile}
								identity={identity}
								isReadOnly={isReadOnly}
								isRequiredFile={isRequiredFile}
								refs={refs}
							/>
						</ArrayFieldItem>
					);
				})}

				<Box hidden={isReadOnly}>
					<UnstyledButton
						onClick={appendItem}
						className={`${classes.addButton} ${
							isRequired && isEmptyArray && isErrors ? classes.requiredButton : ''
						}`}
					>
						<Icon.IconPlus size={16} />
						<TFText inherit ml={6}>
							Add {def.visual.label.toLowerCase()}
						</TFText>
						{isRequired && isEmptyArray ? <TFText className={classes.requiredMark}>*</TFText> : ''}
					</UnstyledButton>
				</Box>
			</Stack>
		</>
	);
};

export default ArrayField;
